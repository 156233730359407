
import { reactive, getCurrentInstance, toRefs, ref, defineComponent } from 'vue';
import {
  getConfig,
  addConfig,
  updateConfig,
  deleteConfig,
  getHistory,
  deliveryConfig,
  checkKeyRule,
} from '@/api/settings/config';
import PackagedPagination from '@/components/pagination/Index.vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import dateFormat from '@/utils/date-format';
import { getShowBool } from '@/utils/permission-show-module';
import { useSaList } from '@/shared/hooks/list';

// 状态码
enum ResCode {
  Success,
}
interface TableState {
  historyData: Array<object>;
}
interface ConfigFormState {
  disabled: boolean;
  isEdit: boolean;
  id: string;
  formData: {
    name: string;
    value: string;
    defaultValue: string;
    type: string;
    description: string;
    sensitive: number;
  };
}

export default defineComponent({
  name: 'Config',
  components: {
    PackagedPagination,
  },
  setup() {
    // 表单相关状态
    const tableState: TableState = reactive({
      historyData: [],
    });
    // 新增配置表单数据
    const configForm: ConfigFormState = reactive({
      disabled: false,
      isEdit: false,
      id: '',
      formData: {
        name: '',
        value: '',
        defaultValue: '',
        type: '1',
        description: '',
        sensitive: 0,
      },
    });

    const submitting = ref(false);
    let editOldKey = '';
    // // 服务key校验
    const validatorKeyPass = async (rule: any, value: string, callback: Function) => {
      // 校验规则
      const szReg = /^[a-zA-Z][A-Za-z0-9-_.]+$/;
      if (!szReg.test(value)) {
        callback(new Error('字母、中划线、下划线、小数点包含数字，不能只输入数字不能以数字开头'));
      }
      // 是否是编辑，且没有修改
      if (configForm.isEdit && configForm.formData.name === editOldKey) {
        callback();
      }
      // 后台校验
      const { code, data } = await checkKeyRule({
        name: value,
        scope: 2,
      });
      const { usable } = data;
      if (code === ResCode.Success && !usable) {
        callback(new Error('键已存在!'));
      }
      callback();
    };
    const configRules = {
      name: [
        { required: true, message: '请输入键（Key）', trigger: 'blur' },
        { min: 1, max: 255, message: '长度在 1 到 255 个字符', trigger: 'blur' },
        { validator: validatorKeyPass, trigger: 'blur' },
      ],
      value: [
        { required: true, message: '请输入值（Value）', trigger: 'blur' },
        { min: 1, max: 20, message: '长度在 1 到 20个字符', trigger: 'blur' },
      ],
      defaultValue: [
        { required: true, message: '请输入值（DefaultValue）', trigger: 'blur' },
        { min: 1, max: 20, message: '长度在 1 到 20个字符', trigger: 'blur' },
      ],
      type: [{ required: true, message: '请选则类型', trigger: 'change' }],
    };

    // 获取组件实例
    const instance = getCurrentInstance();

    // 初始化configForm
    function initConfigForm() {
      configForm.isEdit = false;
      configForm.disabled = false;
      configForm.id = '';
      configForm.formData = {
        name: '',
        value: '',
        defaultValue: '',
        type: '1',
        description: '',
        sensitive: 0,
      };
    }

    const { loading, query, total, list, fetchList, handleSearch, handlePageSizeChange, handlePageChange } = useSaList(
      async (query: any) => {
        loading.value = true;
        try {
          const {
            data: { rows, count },
          } = await getConfig({
            ...query,
          });
          list.value = rows;
          total.value = count;
        } catch (e) {
          console.log(e);
        }
        loading.value = false;
      },
    );

    fetchList();

    const labelWidth = ref('100px');
    const addConfigDialog = ref(false);

    const changeHistoryDialog = ref(false);

    const toggleConfigDialog = () => {
      addConfigDialog.value = !addConfigDialog.value;
    };

    // 表单引用
    const configFormRef: any = ref(null);

    // 关闭新增dialog
    function closeConfigForm() {
      initConfigForm();
      toggleConfigDialog();
    }

    // 提交通用配置
    async function submitConfigForm() {
      configFormRef.value.validate(async (valid: boolean) => {
        if (valid) {
          // 添加
          submitting.value = true;
          if (!configForm.isEdit) {
            const { code } = await addConfig({
              ...configForm.formData,
              type: parseInt(configForm.formData.type, 10),
            });
            if (code === 0) {
              fetchList();
            } else {
              (instance as any).proxy.$message({
                type: 'error',
                message: '添加失败',
              });
            }
            submitting.value = false;
            closeConfigForm();
          } else {
            // 编辑
            const { code } = await updateConfig(configForm.id, {
              ...configForm.formData,
              type: parseInt(configForm.formData.type, 10),
            });
            if (code === 0) {
              ElMessage.success('编辑配置成功');
              fetchList();
            } else {
              (instance as any).proxy.$message({
                type: 'error',
                message: '编辑失败',
              });
            }
            submitting.value = false;
            closeConfigForm();
          }
        }
      });
    }

    // 新建配置
    function addNewConfig() {
      toggleConfigDialog();
    }

    // 配置下发
    const issueConfig = async () => {
      const { code } = await deliveryConfig();
      if (code === 0) {
        (instance as any).proxy.$message({
          type: 'success',
          message: '下发配置参数成功',
        });
      } else {
        (instance as any).proxy.$message({
          type: 'error',
          message: '下发参数配置失败',
        });
      }
    };

    // 开启编辑
    function ableEdit() {
      configForm.disabled = false;
    }

    // 编辑配置
    const onEdit = async (rowData: any) => {
      configForm.isEdit = true;
      configForm.disabled = true;
      configForm.id = rowData.id;
      editOldKey = rowData.name;
      configForm.formData = { ...configForm.formData, ...rowData, type: String(rowData.type) };
      configForm.formData.sensitive = configForm.formData.sensitive || 0;
      toggleConfigDialog();
    };

    // 变更历史
    const changeHistory = async (rowData: any) => {
      const searchProps = {
        page: 1,
        pageSize: 20,
      };
      const { code, data } = await getHistory(rowData.id, searchProps);
      if (code === 0) {
        tableState.historyData = data.rows || [];
        changeHistoryDialog.value = true;
      } else {
        (instance as any).proxy.$message({
          type: 'error',
          message: '获取变更历史失败',
        });
      }
    };

    // 关闭变更历史
    function closeChangeHistory() {
      changeHistoryDialog.value = false;
    }

    // 删除配置
    const onDelete = async (rowData: any) => {
      ElMessageBox.confirm(`是否删除已选服务?`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code }: any = await deleteConfig(rowData.id);
        if (code === 0) {
          (instance as any).proxy.$message({
            type: 'success',
            message: '删除成功',
          });
          fetchList();
        } else {
          (instance as any).proxy.$message({
            type: 'error',
            message: '删除失败',
          });
        }
      });
    };

    return {
      ...toRefs(tableState),
      loading,
      query,
      total,
      list,
      fetchList,
      handleSearch,
      handlePageSizeChange,
      handlePageChange,
      addNewConfig,
      issueConfig,
      labelWidth,
      configForm,
      configRules,
      addConfigDialog,
      changeHistoryDialog,
      configFormRef,
      submitConfigForm,
      closeConfigForm,
      closeChangeHistory,
      ableEdit,
      onEdit,
      changeHistory,
      onDelete,
      dateFormat,
      getShowBool,
      submitting,
    };
  },
});
